import {
  FETCH_TABLE_COLUMNS_SUCCESS,
  FETCH_TABLE_COLUMNS_FAILED
} from "../types/tableColumns";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_TABLE_COLUMNS_SUCCESS:
      return {
        ...state,
        data: action.tableColumns
      };
    case FETCH_TABLE_COLUMNS_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
