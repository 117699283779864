import { SET_LANGUAGE } from "../types/settings";

const initialState = {
  language: localStorage.language ? localStorage.language : "en"
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      localStorage.language = action.language;
      return {
        ...state,
        language: action.language
      };
    default: {
      return state;
    }
  }
}
