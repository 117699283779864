import { SET_USER } from "../types/user";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.user
      };

    default:
      return state;
  }
}
