import {
  FETCH_PRODUCTS,
  SET_PRODUCT,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_INITIALS,
  CREATE_HISTORY,
  UPDATE_PRODUCT_TEMPLATE,
  SET_SEARCH_PRODUCTS,
  SET_IS_SEARCH_PRODUCTS
} from "../types/products";

export const fetchProducts = () => {
  return dispatch => {
    dispatch({
      type: FETCH_PRODUCTS
    });
  };
};

export const fetchProductInitials = productId => {
  return dispatch => {
    dispatch({
      type: FETCH_PRODUCTS_INITIALS,
      productId
    });
  };
};
export const setProduct = product => {
  return dispatch => {
    dispatch({
      type: SET_PRODUCT,
      product
    });
  };
};

export const setSearchProducts = searchingProducts => {
  return dispatch => {
    dispatch({
      type: SET_SEARCH_PRODUCTS,
      searchingProducts
    });
  };
};
export const setIsSearchProducts = isSearchingProducts => {
  return dispatch => {
    dispatch({
      type: SET_IS_SEARCH_PRODUCTS,
      isSearchingProducts
    });
  };
};

export const setProducts = products => {
  return dispatch => {
    dispatch({
      type: FETCH_PRODUCTS_SUCCESS,
      products: products
    });
  };
};

export const createHistory = templateId => {
  return dispatch => {
    dispatch({
      type: CREATE_HISTORY,
      templateId
    });
  };
};

export const updateProductTemplate = (templateId, data) => {
  return dispatch => {
    dispatch({
      type: UPDATE_PRODUCT_TEMPLATE,
      templateId,
      data
    });
  };
};
