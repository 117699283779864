import {
  FETCH_DB_TABLES_SUCCESS,
  FETCH_DB_TABLES_FAILED
} from "../types/dbTables";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_DB_TABLES_SUCCESS:
      return {
        ...state,
        data: action.dbTables
      };
    case FETCH_DB_TABLES_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
