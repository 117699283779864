import {
  SET_COLUMNS,
  SET_MENTION_DATA,
  SET_NINJA_DL_MENTIONS
} from "../types/autoComplete";
import { FETCH_DATA_SOURCE_COLUMNS_SUCCESS } from "../types/productTemplate";

const initialState = {
  mentionData: [
    { id: "create_date", display: "create_date" },
    { id: "week", display: "WEEK" },
    { id: "month", display: "MONTH" },
    { id: "month_text", display: "MONTH_TEXT" },
    { id: "year", display: "YEAR" },
    { id: `"to"`, display: `"TO"` },
    { id: "recipient_type", display: "RECIPIENT_TYPE" },
    { id: "hierarchy_lvl", display: "HIERARCHY_LVL" },
    { id: "manager_name", display: "MANAGER_NAME" },
    { id: "manager", display: "MANAGER" },
    { id: "full_name", display: "FULL_NAME" },
    { id: "asset_name", display: "ASSET_NAME" },
    { id: "level", display: "LEVEL" },
    { id: "edition", display: "EDITION" },
    { id: "name", display: "NAME" }
  ],
  emailMentions: [{ id: "manager", display: "MANAGER" }],
  heatmapSelections: [{ id: "individual", title: "individual" }],
  initialMentionData: [
    { id: "create_date", display: "CREATE_DATE" },
    { id: "report_date", display: "REPORT_DATE" },
    { id: "week", display: "WEEK" },
    { id: "month", display: "MONTH" },
    { id: "month_text", display: "MONTH_TEXT" },
    { id: "year", display: "YEAR" },
    { id: `"to"`, display: `"TO"` },
    { id: "recipient_type", display: "RECIPIENT_TYPE" },
    { id: "hierarchy_lvl", display: "HIERARCHY_LVL" },
    { id: "manager_name", display: "MANAGER_NAME" },
    { id: "manager", display: "MANAGER" },
    { id: "full_name", display: "FULL_NAME" },
    { id: "asset_name", display: "ASSET_NAME" },
    { id: "level", display: "LEVEL" },
    { id: "edition", display: "EDITION" },
    { id: "name", display: "NAME" },
    { id: "shift_name", display: "SHIFT_NAME" }
  ],
  columns: [
    {
      columnName: "full_name"
    },
    {
      columnName: "asset_name"
    },
    {
      columnName: "email"
    },
    {
      columnName: "identity_number"
    },
    {
      columnName: "manager_name"
    },
    {
      columnName: "contact"
    }
  ],
  initialColumns: [
    {
      columnName: "full_name"
    },
    {
      columnName: "asset_name"
    },
    {
      columnName: "identity_number"
    },
    {
      columnName: "manager_name"
    },
    {
      columnName: "contact"
    }
  ],
  initialNinjaDlMentions: [
    { id: "email", name: "email", display: "EMAIL" },
    { id: "first_name", name: "first_name", display: "FIRST_NAME" },
    { id: "edition", name: "edition", display: "EDITION" },
    { id: "last_name", name: "last_name", display: "LAST_NAME" },
    { id: "display_name", name: "display_name", display: "DISPLAY_NAME" },
    { id: "mobile_number", name: "mobile_number", display: "MOBILE_NUMBER" }
  ],
  ninjaDlMentions: [
    { id: "email", name: "email", display: "EMAIL" },
    { id: "first_name", name: "first_name", display: "FIRST_NAME" },
    { id: "edition", name: "edition", display: "EDITION" },
    { id: "last_name", name: "last_name", display: "LAST_NAME" },
    { id: "display_name", name: "display_name", display: "DISPLAY_NAME" },
    { id: "mobile_number", name: "mobile_number", display: "MOBILE_NUMBER" }
  ],
  ninjaDataSourceColumns: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_MENTION_DATA:
      return {
        ...state,
        mentionData: [...state.initialMentionData, ...action.mentionData]
      };
    case SET_COLUMNS:
      return {
        ...state,
        columns: [
          ...state.initialColumns,
          ...(action.columns && action.columns.length > 0 ? action.columns : [])
        ]
      };
    case FETCH_DATA_SOURCE_COLUMNS_SUCCESS:
      return {
        ...state,
        ninjaDataSourceColumns: action.ninjaDataSourceColumns ?? []
      };
    case SET_NINJA_DL_MENTIONS:
      return {
        ...state,
        ninjaDlMentions: [
          ...state.initialNinjaDlMentions,
          ...(action.columns && action.columns.length > 0
            ? action.columns.map(c => {
                return { ...c, display: c.name?.toUpperCase(), id: c.name };
              })
            : [])
        ],
        emailMentions: [
          ...(action.columns && action.columns.length > 0
            ? action.columns.map(c => {
                return { ...c, display: c.name?.toUpperCase(), id: c.name };
              })
            : [])
        ]
      };
    default:
      return state;
  }
}
