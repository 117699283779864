import {
  FETCH_CHANNELS_FAILED,
  FETCH_CHANNELS_SUCCESS
} from "../types/channels";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_CHANNELS_SUCCESS:
      return {
        ...state,
        data: action.channels
      };
    case FETCH_CHANNELS_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
