import { SET_COLUMN_OPTIONS } from "../types/columnOptions";
import { RESET_BUILDER } from "../types/reset";

const initialState = {
  data: [
    {
      value: `from`,
      key: "From",
      type: 0
    },
    {
      value: `from_sms`,
      key: "From sms",
      type: 3
    },
    {
      value: `from_name`,
      key: "From Name",
      type: 2
    },
    {
      value: `reply_to`,
      key: "Reply To",
      type: 0
    },
    {
      value: `subject`,
      key: "Subject",
      mention: "@[NAME](NAME) @[EDITION](EDITION) @[WEEK](WEEK)",
      type: 2
    },
    {
      value: `preview`,
      key: "Preview",
      mention: "@[NAME](NAME) @[EDITION](EDITION) @[WEEK](WEEK)",
      mapValue:
        "@[NAME](NAME) - check your @[EDITION](EDITION) for week @[WEEK](WEEK)",
      type: 2
    },
    {
      value: `cc`,
      key: "CC",
      mention: "",
      type: 2
    },
    {
      value: `additional_to`,
      key: "ADDITIONAL_TO",
      mention: "",
      type: 2
    }
  ]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_COLUMN_OPTIONS:
      return {
        ...state,
        data: action.columnOptions
      };
    case RESET_BUILDER:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
