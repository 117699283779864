import { SET_TENANT_FAILED, SET_TENANT_SUCCESS } from "../types/tenant";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TENANT_SUCCESS:
      var tenantPermission;
      localStorage.tenant_id =
        action && action.tenant
          ? action.tenant.tenant_id || action.tenant.tenantId
          : null;

      if (
        action.tenant &&
        action.tenant.permissions &&
        action.tenant.permissions.length
      ) {
        var tenantPermissions = action.tenant.permissions.filter(
          item => item.workspace_id == null
        );
        if (tenantPermissions && tenantPermissions.length > 1) {
          tenantPermission = tenantPermissions[0];
          for (var index = 1; index < tenantPermissions.length; index++) {
            if (
              tenantPermissions[index].permission_weight >
              tenantPermission.permission_weight
            ) {
              tenantPermission = tenantPermissions[index];
            }
          }
        } else if (tenantPermissions.length == 1) {
          tenantPermission = tenantPermissions[0];
        }
      }
      var tenantSettingsFlag = false;
      var adminWorkspacesIds = null;
      if (
        action.tenant &&
        action.tenant.permissions &&
        action.tenant.permissions.length
      ) {
        var ownerObj = action.tenant.permissions.find(
          item => item.is_tenant_owner
        );

        if (ownerObj != null) {
          tenantSettingsFlag = true;
        } else {
          var adminOrOwners = action.tenant.permissions.filter(
            item =>
              item.workspace_id == null &&
              (["owner", "admin"].includes(item.permission_type) ||
                ["owner", "admin"].includes(item.permissionType))
          );
          if (adminOrOwners && adminOrOwners.length) {
            tenantSettingsFlag = true;
          } else tenantSettingsFlag = false;
        }

        if (tenantSettingsFlag == false) {
          var workspaceAdmins = action.tenant.permissions.filter(
            item =>
              item.workspace_id != null &&
              (["owner", "admin"].includes(item.permission_type) ||
                ["owner", "admin"].includes(item.permissionType))
          );

          adminWorkspacesIds =
            workspaceAdmins != null && workspaceAdmins.length > 0
              ? workspaceAdmins.map(item => item.workspace_id)
              : null;
        }
      } else {
        tenantSettingsFlag = false;
      }

      return {
        ...state,
        ...action.tenant,
        permission_type: tenantPermission
          ? tenantPermission.permission_type || tenantPermission.permissionType
          : "read_only",
        selectedTenant: action.tenant,
        adminWorkspacesIds: adminWorkspacesIds,
        tenantSettingsFlag: tenantSettingsFlag
      };
    case SET_TENANT_FAILED: {
      return {
        ...state
      };
    }
    default:
      return state;
  }
}
