import { SET_DL_COLUMNS } from "../types/dl";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_DL_COLUMNS:
      return {
        ...state,
        data: action.dlColumns
      };
    default:
      return state;
  }
}
