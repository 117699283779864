import {
  FETCH_CTL_RULES_SUCCESS,
  FETCH_CTL_RULES_FAILED
} from "../types/ctlRules";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_CTL_RULES_SUCCESS:
      return {
        ...state,
        data: action.dataSources
      };
    case FETCH_CTL_RULES_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
