import {
  FETCH_PRODUCT_DATA,
  FETCH_PRODUCT_DATA_FAILED,
  FETCH_PRODUCT_DATA_SUCCESS,
  FETCH_PRODUCT_DATES_FAILED,
  FETCH_PRODUCT_DATES_SUCCESS,
  FETCH_SENDLIST_DATA,
  FETCH_SENDLIST_DATA_FAILED,
  FETCH_SENDLIST_DATA_SUCCESS
} from "../types/productData";
import { RESET_BUILDER } from "../types/reset";
import moment from "moment";
import { SET_DUMMY_DATA } from "../types/products";

const initialState = {
  sendLists: [],
  data: null,
  dates: [],
  dumbyDataSelected: false,
  selectedDatePeriod: moment().format("YYYY-MM-DD"),
  sendlistId: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_DATA:
      return {
        ...state,
        selectedDatePeriod: action.selectedDatePeriod
      };
    case FETCH_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        sendLists: action.productData
      };
    case FETCH_PRODUCT_DATA_FAILED:
      return {
        ...state
      };
    case FETCH_PRODUCT_DATES_SUCCESS:
      return {
        ...state,
        dates: action.productDates,
        selectedDatePeriod: action.selectedDatePeriod
      };
    case FETCH_PRODUCT_DATES_FAILED:
      return {
        ...state
      };
    case FETCH_SENDLIST_DATA:
      return {
        ...state,
        dumbyDataSelected: false,
        sendlistId: action.sendlistId
      };
    case FETCH_SENDLIST_DATA_SUCCESS:
      return {
        ...state,
        data: action.data
      };
    case FETCH_SENDLIST_DATA_FAILED:
      return {
        ...state
      };
    case RESET_BUILDER:
      return {
        ...initialState
      };
    case SET_DUMMY_DATA:
      return {
        ...state,
        dumbyDataSelected: action.dumbyDataSelected,
        sendlistId: action.dumbyDataSelected ? null : state.sendlistId,
        data: action.dumbyDataSelected
          ? null
          : state.data
          ? { ...state.data }
          : null
      };
    default:
      return state;
  }
}
