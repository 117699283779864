import {
  CREATE_MAT_VIEW,
  CREATE_MAT_VIEW_FAILED,
  CREATE_MAT_VIEW_SUCCESS,
  FETCH_PRODUCT_CALCULATION_FAILED,
  FETCH_PRODUCT_CALCULATION_SUCCESS,
  FETCH_PRODUCT_USERS_FAILED,
  FETCH_PRODUCT_USERS_SUCCESS,
  FETCH_REAL_TIME_KPI_DATA_FAILED,
  FETCH_REAL_TIME_KPI_DATA_SUCCESS,
  SET_PRODUCT_USER
} from "../types/realTimeData";
import { RESET_BUILDER } from "../types/reset";

const initialState = {
  data: null,
  users: [],
  selectedUser: {},
  isCreatingView: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_REAL_TIME_KPI_DATA_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.data }
      };
    case FETCH_REAL_TIME_KPI_DATA_FAILED:
      return {
        ...state
      };
    case RESET_BUILDER:
      return {
        ...initialState
      };
    case FETCH_PRODUCT_USERS_SUCCESS:
      return {
        ...state,
        users: action.users,
        selectedUser:
          action.users && action.users.length > 0
            ? {
                ...action.users[0],
                hidden: JSON.parse(action.users[0].hidden || {})
              }
            : {}
      };
    case FETCH_PRODUCT_USERS_FAILED:
      return {
        ...state
      };
    case FETCH_PRODUCT_CALCULATION_SUCCESS:
      return {
        ...state,
        data: { ...action.data }
      };
    case FETCH_PRODUCT_CALCULATION_FAILED:
      return {
        ...state
      };
    case SET_PRODUCT_USER:
      return {
        ...state,
        selectedUser: {
          ...action.selectedUser,
          hidden: JSON.parse(action.selectedUser?.hidden || "{}")
        }
      };
    case CREATE_MAT_VIEW:
      return {
        ...state,
        isCreatingView: true
      };
    case CREATE_MAT_VIEW_SUCCESS:
      return {
        ...state,
        isCreatingView: false
      };
    case CREATE_MAT_VIEW_FAILED:
      return {
        ...state,
        isCreatingView: false
      };
    default:
      return state;
  }
}
