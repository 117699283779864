import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import settingsReducer from "./settings";
import snackbarReducer from "./snackbar";
import productReducer from "./products";
import snippetReducer from "./snippets";
import dbTablesReducer from "./dbTables";
import tableColumnsReducer from "./tableColumns";
import liveDataReducer from "./liveData";
import iconsReducer from "./icons";
import dataSourceReducer from "./dataSources";
import ctlRuleReducer from "./ctlRules";
import dataSourceConditionReducer from "./dataSourceCondition";
import workspaceReducer from "./workspaces";
import appReducer from "./apps";
import dlTableReducer from "./dlTables";
import dlColumnReducer from "./dlColumns";
import channelsReducer from "./channels";
import activeChannelReducer from "./activeChannel";
import userReducer from "./user";
import tenantReducer from "./tenant";
import columnOptionsReducer from "./columnOptions";
import schedulesReducer from "./schedules";
import dlReducer from "./dl";
import managedUsersReducer from "./managedUser";
import autoCompleteReducer from "./autoComplete";
import templateSnippetReducer from "./templateSnippet";
import productTemplateReducer from "./productTemplate";
import productDataReducer from "./productData";
import ninjaDlReducer from "./ninjaDl";
import realTimeDataReducer from "./realTimeData";
import staticTableReducer from "./staticTable";

export default combineReducers({
  routing: routerReducer,
  settings: settingsReducer,
  snackbar: snackbarReducer,
  products: productReducer,
  snippets: snippetReducer,
  tenant: tenantReducer,
  user: userReducer,
  dbTables: dbTablesReducer,
  tableColumns: tableColumnsReducer,
  liveData: liveDataReducer,
  icons: iconsReducer,
  dataSources: dataSourceReducer,
  ctlRules: ctlRuleReducer,
  dataSourceConditions: dataSourceConditionReducer,
  workspaces: workspaceReducer,
  apps: appReducer,
  dlTables: dlTableReducer,
  dlColumns: dlColumnReducer,
  channels: channelsReducer,
  activeChannel: activeChannelReducer,
  columnOptions: columnOptionsReducer,
  schedules: schedulesReducer,
  dl: dlReducer,
  managedUsers: managedUsersReducer,
  autoComplete: autoCompleteReducer,
  templateSnippet: templateSnippetReducer,
  productTemplate: productTemplateReducer,
  productData: productDataReducer,
  ninjaDl: ninjaDlReducer,
  realTimeData: realTimeDataReducer,
  staticTable: staticTableReducer
});
