import {
  FETCH_WORKSPACES_SUCCESS,
  FETCH_WORKSPACES_FAILED,
  SELECT_WORKSPACE,
  FETCH_SEARCH_WORKSPACE_COMPONENTS_SUCCESS,
  FETCH_SEARCH_WORKSPACE_COMPONENTS_FAILED,
  SET_SEARCH_PRODUCTS,
  SET_IS_SEARCH_PRODUCTS
} from "../types/workspaces";
import Cookies from "js-cookie";

const initialState = {
  selectedWorkspace: null,
  data: [],
  searchWorkspaceComponents: [],
  isSearchingProducts: false,
  searchingProducts: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_WORKSPACES_SUCCESS:
      return {
        ...state,
        data: action.workspaces,
        selectedWorkspace: Cookies.get("selectedWorkspace")
          ? JSON.parse(Cookies.get("selectedWorkspace"))
          : action.workspaces && action.workspaces.length > 0
          ? action.workspaces[0]
          : null
      };
    case FETCH_WORKSPACES_FAILED:
      return {
        ...state
      };
    case SELECT_WORKSPACE:
      return {
        ...state,
        selectedWorkspace: action.selectedWorkspace
      };
    case FETCH_SEARCH_WORKSPACE_COMPONENTS_SUCCESS:
      return {
        ...state,
        searchWorkspaceComponents: action.searchWorkspaceComponents
      };
    case FETCH_SEARCH_WORKSPACE_COMPONENTS_FAILED:
      return {
        ...state
      };
    case SET_SEARCH_PRODUCTS:
      return {
        ...state,
        searchingProducts: action.searchingProducts
      };
    case SET_IS_SEARCH_PRODUCTS:
      return {
        ...state,
        isSearchingProducts: action.isSearchingProducts
      };

    default:
      return state;
  }
}
