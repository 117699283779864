import {
  FETCH_DATA_SOURCES_SUCCESS,
  FETCH_DATA_SOURCES_FAILED,
  FETCH_TEMPLATE_DATA_SOURCES_SUCCESS,
  FETCH_TEMPLATE_DATA_SOURCES_FAILED,
  SET_TEMPLATE_DATA_SOURCES,
  FETCH_TEMPLATE_DATA_SOURCES_ELEMENTS_SUCCESS,
  FETCH_TEMPLATE_DATA_SOURCES_ELEMENTS_FAILED,
  FETCH_DATA_SOURCES,
  SET_SELECTED_DATASOURCE_TYPE,
  FETCH_FORMS_SUCCESS,
  FETCH_FORMS_FAILED,
  FETCH_SEARCH_DATA_SOURCES_SUCCESS,
  FETCH_SEARCH_DATA_SOURCES_FAILED
} from "../types/dataSources";

const initialState = {
  templateDataSources: [],
  dataSourceElements: [{ id: null, title: "None" }],
  data: [],
  searchedDataSources: [],
  error: false,
  hasMore: false,
  isLoading: false,
  selectedDatasourceType: "all",
  formCount: null,
  tableCount: null,
  searchedForms: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_DATA_SOURCES_SUCCESS:
      return {
        ...state,
        data: action.dataSources,
        searchedDataSources: action.dataSources,
        tableCount: action.tableCount,
        formCount: action.formCount,
        hasMore: action.hasMore,
        isLoading: false
      };
    case FETCH_DATA_SOURCES:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_DATA_SOURCES_FAILED:
      return {
        ...state,
        isLoading: false
      };
    case FETCH_TEMPLATE_DATA_SOURCES_SUCCESS:
      return {
        ...state,
        templateDataSources: action.templateDataSources
      };
    case FETCH_TEMPLATE_DATA_SOURCES_FAILED:
      return {
        ...state
      };
    case SET_TEMPLATE_DATA_SOURCES:
      return {
        ...state,
        templateDataSources: action.templateDataSources
      };
    case FETCH_TEMPLATE_DATA_SOURCES_ELEMENTS_SUCCESS:
      return {
        ...state,
        dataSourceElements: [
          ...state.dataSourceElements,
          ...action.dataSourceElements
        ]
      };
    case FETCH_TEMPLATE_DATA_SOURCES_ELEMENTS_FAILED:
      return {
        ...state
      };
    case SET_SELECTED_DATASOURCE_TYPE:
      return {
        ...state,
        selectedDatasourceType: action.selectedDatasourceType
      };
    case FETCH_FORMS_SUCCESS:
      return {
        ...state,
        searchedForms: action.searchedForms
      };
    case FETCH_FORMS_FAILED:
      return {
        ...state
      };
    case FETCH_SEARCH_DATA_SOURCES_SUCCESS:
      return {
        ...state,
        searchedDataSources: action.searchedDataSources
      };
    case FETCH_SEARCH_DATA_SOURCES_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
