import { OPEN_SNACKBAR, CLOSE_SNACKBAR } from "../types/snackbar";

export const openSnackbar = (snackbarType, message) => {
  return dispatch => {
    dispatch({
      type: OPEN_SNACKBAR,
      snackbarType: snackbarType,
      message: message
    });
  };
};

export const closeSnackbar = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_SNACKBAR
    });
  };
};
