import {
  FETCH_DL_TABLES_SUCCESS,
  FETCH_DL_TABLES_FAILED
} from "../types/dlTables";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_DL_TABLES_SUCCESS:
      return {
        ...state,
        data: action.dlTables
      };
    case FETCH_DL_TABLES_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
