import { FETCH_ICONS_SUCCESS, FETCH_ICONS_FAILED } from "../types/icons";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_ICONS_SUCCESS:
      return {
        ...state,
        data: action.icons
      };
    case FETCH_ICONS_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
