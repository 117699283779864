import {
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILED,
  SET_PRODUCT,
  CREATE_HISTORY,
  CREATE_HISTORY_SUCCEEDED,
  CREATE_HISTORY_FAILED,
  FETCH_PRODUCTS_INITIALS_SUCCESS,
  FETCH_PRODUCTS_INITIALS_FAILED,
  UPDATE_PRODUCT_TEMPLATE_SUCCEEDED,
  UPDATE_PRODUCT_TEMPLATE_FAILED,
  SET_SEARCH_PRODUCTS,
  SET_IS_SEARCH_PRODUCTS
} from "../types/products";
import { RESET_BUILDER } from "../types/reset";

const initialState = {
  product: {},
  productsInitials: {},
  searchingProducts: [],
  isSearchingProducts: false,
  isCreatingHistoryLoading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: action.products
      };
    case SET_PRODUCT:
      return {
        ...state,
        product: action.product
      };
    case SET_SEARCH_PRODUCTS:
      return {
        ...state,
        searchingProducts: action.searchingProducts
      };
    case SET_IS_SEARCH_PRODUCTS:
      return {
        ...state,
        isSearchingProducts: action.isSearchingProducts
      };
    case FETCH_PRODUCTS_FAILED:
      return {
        ...state
      };
    case FETCH_PRODUCTS_INITIALS_SUCCESS:
      return {
        ...state,
        productsInitials: action.productsInitials
      };
    case FETCH_PRODUCTS_INITIALS_FAILED:
      return {
        ...state
      };
    case CREATE_HISTORY:
      return {
        ...state,
        isCreatingHistoryLoading: true
      };
    case CREATE_HISTORY_SUCCEEDED:
      return {
        ...state,
        isCreatingHistoryLoading: false
      };
    case CREATE_HISTORY_FAILED:
      return {
        ...state,
        isCreatingHistoryLoading: false
      };
    case UPDATE_PRODUCT_TEMPLATE_SUCCEEDED:
      return {
        ...state,
        product: { ...state.product, ...action.productTemplate }
      };
    case UPDATE_PRODUCT_TEMPLATE_FAILED:
      return {
        ...state
      };
    case RESET_BUILDER:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
