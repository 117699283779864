import { RESET_BUILDER } from "../types/reset";
import {
  FETCH_TEMPLATE_SNIPPETS,
  FETCH_TEMPLATE_SNIPPETS_FAILED,
  FETCH_TEMPLATE_SNIPPETS_SUCCESS,
  UPDATE_TEMPLATE_SNIPPET_FAILED,
  UPDATE_TEMPLATE_SNIPPET_SUCCESS,
  ADD_TEMPLATE_SNIPPET_SUCCESS,
  ADD_TEMPLATE_SNIPPET_FAILED,
  DELETE_TEMPLATE_SNIPPET_SUCCESS,
  DELETE_TEMPLATE_SNIPPET_FAILED,
  REORDER_TEMPLATE_SNIPPET_FAILED,
  REORDER_TEMPLATE_SNIPPET_SUCCESS,
  DUPLICATE_TEMPLATE_SNIPPET_SUCCESS,
  DUPLICATE_TEMPLATE_SNIPPET_FAILED,
  SET_TEMPLATE_SNIPPETS
} from "../types/templateSnippet";

const initialState = {
  templateSnippets: [],
  isFetchingLoading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_TEMPLATE_SNIPPETS:
      return {
        ...state,
        isFetchingLoading: true
      };
    case FETCH_TEMPLATE_SNIPPETS_SUCCESS:
      return {
        ...state,
        templateSnippets: [...action.templateSnippets],
        isFetchingLoading: false
      };
    case FETCH_TEMPLATE_SNIPPETS_FAILED:
      return {
        ...state,
        isFetchingLoading: false
      };
    case UPDATE_TEMPLATE_SNIPPET_SUCCESS:
      return {
        ...state,
        templateSnippets: [...action.templateSnippets]
      };
    case UPDATE_TEMPLATE_SNIPPET_FAILED:
      return {
        ...state
      };
    case ADD_TEMPLATE_SNIPPET_SUCCESS:
      return {
        ...state,
        templateSnippets: [...action.templateSnippets],
        destinationIndex: action.destinationIndex,
        templateId: action.templateId,
        addDivider: action.addDivider
      };
    case ADD_TEMPLATE_SNIPPET_FAILED:
      return {
        ...state
      };
    case DELETE_TEMPLATE_SNIPPET_SUCCESS:
      return {
        ...state,
        templateSnippets: [...action.templateSnippets]
      };
    case DELETE_TEMPLATE_SNIPPET_FAILED:
      return {
        ...state
      };
    case REORDER_TEMPLATE_SNIPPET_SUCCESS:
      return {
        ...state,
        templateSnippets: [...action.templateSnippets]
      };
    case REORDER_TEMPLATE_SNIPPET_FAILED:
      return {
        ...state,
        templateSnippets: [...action.templateSnippets]
      };
    case DUPLICATE_TEMPLATE_SNIPPET_SUCCESS:
      return {
        ...state,
        templateSnippets: [...action.templateSnippets]
      };
    case DUPLICATE_TEMPLATE_SNIPPET_FAILED:
      return {
        ...state
      };
    case SET_TEMPLATE_SNIPPETS:
      return {
        ...state,
        templateSnippets: [...action.templateSnippets]
      };
    case RESET_BUILDER:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
