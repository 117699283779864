import {
  FETCH_DLS,
  SET_SELECTED_DL,
  UPDATE_DL,
  REMOVE_DL,
  SET_SAVING,
  SET_UPLOAD_TASKID,
  FETCH_COLUMN_VALUES
} from "../types/ninjaDl";

export const fetchDls = () => {
  return dispatch => {
    dispatch({
      type: FETCH_DLS
    });
  };
};

export const setSelectedDl = dl => {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_DL,
      dl
    });
  };
};

export const updateDl = dl => {
  return dispatch => {
    dispatch({
      type: UPDATE_DL,
      dl
    });
  };
};

export const removeDl = dl => {
  return dispatch => {
    dispatch({
      type: REMOVE_DL,
      dl
    });
  };
};

export const setSaving = value => {
  return dispatch => {
    dispatch({
      type: SET_SAVING,
      value
    });
  };
};

export const setUploadTaskId = id => {
  return dispatch => {
    dispatch({
      type: SET_UPLOAD_TASKID,
      id
    });
  };
};

export const fetchColumnValues = (dlId, column, query) => {
  return dispatch => {
    dispatch({
      type: FETCH_COLUMN_VALUES,
      dlId,
      column,
      query
    });
  };
};
