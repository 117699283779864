import {
  FETCH_PRODUCT_USERS,
  FETCH_PRODUCT_CALCULATION,
  SET_PRODUCT_USER,
  CREATE_MAT_VIEW,
  UPDATE_DL_PROCEDURE,
  UPDATE_DATA_SOURCE_PROCEDURE
} from "../types/realTimeData";

export const fetchProductUsers = () => {
  return {
    type: FETCH_PRODUCT_USERS
  };
};

export const setProductUser = selectedUser => {
  return {
    type: SET_PRODUCT_USER,
    selectedUser
  };
};

export const fetchProductCalculation = () => {
  return {
    type: FETCH_PRODUCT_CALCULATION
  };
};

export const createMatView = () => {
  return {
    type: CREATE_MAT_VIEW
  };
};

export const updateDlProcedure = () => {
  return {
    type: UPDATE_DL_PROCEDURE
  };
};

export const updateDataSourceProcedure = () => {
  return {
    type: UPDATE_DATA_SOURCE_PROCEDURE
  };
};
