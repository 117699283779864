import {
  FETCH_SCHEDULES,
  FETCH_SCHEDULES_FAILED,
  FETCH_SCHEDULES_SUCCESS,
  FETCH_SCHEDULE_INFO_FAILED,
  FETCH_SCHEDULE_INFO_SUCCESS,
  SET_SCHEDULES,
  FETCH_SCHEDULE_INFO
} from "../types/schedules";

const initialState = {
  loading: false,
  error: false,
  scheduleInfo: null,
  scheduleInfoLoading: false,
  scheduleInfoError: false,
  productId: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SCHEDULES:
      return {
        ...state,
        loading: false,
        error: false,
        schedules: action.schedules
      };
    case FETCH_SCHEDULES:
      return {
        ...state,
        loading: true
      };
    case FETCH_SCHEDULE_INFO:
      return {
        ...state,
        scheduleInfoLoading: true
      };
    case FETCH_SCHEDULES_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      };
    case FETCH_SCHEDULES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        schedules: action.schedules
      };
    case FETCH_SCHEDULE_INFO_FAILED:
      return {
        ...state,
        scheduleInfoLoading: false,
        scheduleInfoError: true
      };
    case FETCH_SCHEDULE_INFO_SUCCESS:
      return {
        ...state,
        scheduleInfoError: false,
        scheduleInfoLoading: false,
        scheduleInfo: action.scheduleInfo,
        productId: action.productId
      };
    default:
      return state;
  }
}
