import {
  FETCH_ALL_USERS_SUCCEEDED,
  FETCH_ALL_USERS_FAILED,
  FETCH_USERS_SUCCEEDED,
  FETCH_USERS_FAILED,
  FETCH_ASSETS_SUCCEEDED,
  FETCH_ASSETS_FAILED,
  UPDATE_ASSETS_SUCCEEDED,
  UPDATE_USERS_SUCCEEDED,
  ADD_USER_SUCCEEDED,
  SET_FILTERED_USERS,
  SET_FILTERED_ASSETS,
  SET_FILTERED_ALL_USERS,
  UPDATE_ALL_USERS_SUCCEEDED,
  FETCH_ALL_ASSETS_SUCCEEDED,
  FETCH_ALL_ASSETS_FAILED,
  ADD_ASSET_SUCCEEDED
} from "../types/dl";

const initialState = {
  allUsers: [],
  allAssets: [],
  filteredAllUsers: [],
  users: [],
  filteredUsers: [],
  assets: [],
  filteredAssets: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_USERS_SUCCEEDED:
      return {
        ...state,
        allUsers: action.allUsers,
        filteredAllUsers: action.allUsers
      };
    case FETCH_ALL_USERS_FAILED:
      return {
        ...state
      };
    case FETCH_ALL_ASSETS_SUCCEEDED:
      return {
        ...state,
        allAssets: action.allAssets
      };
    case FETCH_ALL_ASSETS_FAILED:
      return {
        ...state
      };
    case FETCH_USERS_SUCCEEDED:
      return {
        ...state,
        users: action.users,
        filteredUsers: action.users
      };
    case FETCH_USERS_FAILED:
      return {
        ...state
      };
    case FETCH_ASSETS_SUCCEEDED:
      return {
        ...state,
        assets: action.assets,
        filteredAssets: action.assets
      };
    case FETCH_ASSETS_FAILED:
      return {
        ...state
      };
    case UPDATE_ASSETS_SUCCEEDED:
      return {
        ...state,
        assets: [...action.assets]
      };
    case UPDATE_USERS_SUCCEEDED:
      return {
        ...state,
        users: [...action.users]
      };
    case UPDATE_ALL_USERS_SUCCEEDED:
      return {
        ...state,
        allUsers: [...action.allUsers],
        filteredAllUsers: [...action.filteredAllUsers]
      };
    case ADD_USER_SUCCEEDED:
      return {
        ...state,
        users: [...action.users],
        filteredUsers: [...action.users]
      };
    case ADD_ASSET_SUCCEEDED:
      return {
        ...state,
        assets: [...action.assets],
        filteredAssets: [...action.assets]
      };
    case SET_FILTERED_USERS:
      return {
        ...state,
        filteredUsers: [...action.users]
      };
    case SET_FILTERED_ASSETS:
      return {
        ...state,
        filteredAssets: [...action.assets]
      };
    case SET_FILTERED_ALL_USERS:
      return {
        ...state,
        filteredAllUsers: [...action.filteredAllUsers]
      };
    default:
      return state;
  }
}
