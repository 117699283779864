import {
  PUBLISH_TEMPLATE,
  PUBLISH_TEMPLATE_FAILED,
  PUBLISH_TEMPLATE_SUCCESS,
  SET_SELECTED_TEMPLATE_TAB,
  SET_TEMPLATE,
  FETCH_PRODUCT_DL,
  FETCH_PRODUCT_DL_SUCCESS,
  FETCH_PRODUCT_DL_FAILED,
  CANCEL_FETCH_PRODUCT_DL,
  UPDATE_PRODUCT_DL,
  UPDATE_PRODUCT_DL_SUCCESS,
  UPDATE_PRODUCT_DL_FAILED
} from "../types/productTemplate";
import { RESET_BUILDER } from "../types/reset";
const url = new URL(window.location);
const templateId = url.searchParams.get("template_id");

const initialState = {
  isPublishing: false,
  templateId: templateId,
  selectedTab: "builder",
  loadingDl: false,
  productDl: null,
  productDlColumns: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case PUBLISH_TEMPLATE:
      return {
        ...state,
        isPublishing: true
      };
    case SET_TEMPLATE:
      return {
        ...state,
        templateId: action.templateId
      };
    case PUBLISH_TEMPLATE_SUCCESS:
      return {
        ...state,
        isPublishing: false
      };
    case PUBLISH_TEMPLATE_FAILED:
      return {
        ...state,
        isPublishing: false
      };
    case SET_SELECTED_TEMPLATE_TAB:
      return {
        ...state,
        selectedTab: action.selectedTab
      };
    case RESET_BUILDER:
      return {
        ...initialState,
        templateId: state?.templateId
      };
    case FETCH_PRODUCT_DL:
      return {
        ...state,
        loadingDl: true
      };
    case FETCH_PRODUCT_DL_SUCCESS:
      return {
        ...state,
        loadingDl: false,
        productDl: action.productDl,
        productDlColumns: action.productDlColumns
      };
    case FETCH_PRODUCT_DL_FAILED:
      return {
        ...state,
        loadingDl: false,
        productDl: null,
        productDlColumns: null
      };
    case CANCEL_FETCH_PRODUCT_DL:
      return {
        ...state,
        loadingDl: false,
        productDl: null
      };
    case UPDATE_PRODUCT_DL:
      var load = false;
      if (
        state.productDl &&
        state.productDl.dl &&
        action.productDl &&
        action.productDl.dlId != state.productDl.dl.id
      )
        load = true;
      else if (state.productDl && !state.productDl.dl) load = true;
      return {
        ...state,
        loadingDl: load
      };
    case UPDATE_PRODUCT_DL_SUCCESS:
      return {
        ...state,
        loadingDl: false,
        productDl: action.productDl,
        productDlColumns: action.productDlColumns
      };
    case UPDATE_PRODUCT_DL_FAILED:
      return {
        ...state,
        loadingDl: false
      };
    default:
      return state;
  }
}
