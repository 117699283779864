import {
  FETCH_LIVE_DATA_SUCCESS,
  FETCH_LIVE_DATA_FAILED
} from "../types/liveData";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_LIVE_DATA_SUCCESS:
      return {
        ...state,
        data: action.liveData
      };
    case FETCH_LIVE_DATA_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
