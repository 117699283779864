import { SET_ACTIVE_CHANNEL } from "../types/activeChannel";
import channel from "../../const/channel";

const initialState = {
  channel: channel.EMAIL
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_CHANNEL:
      return {
        ...state,
        channel: action.channel
      };
    default:
      return state;
  }
}
