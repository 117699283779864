import { SET_SELECTED_COL, SET_SELECTED_ROW } from "../types/staticTable";

const initialState = {
  selectedCol: null,
  selectedRow: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_COL:
      return {
        ...state,
        selectedCol: action.index
      };
    case SET_SELECTED_ROW:
      return {
        ...state,
        selectedRow: action.index
      };
    default:
      return state;
  }
}
