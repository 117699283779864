import {
  FETCH_DLS_SUCCEEDED,
  FETCH_DLS,
  FETCH_DLS_FAILED,
  SET_SELECTED_DL,
  UPDATE_DL,
  REMOVE_DL,
  SET_SAVING,
  SET_UPLOAD_TASKID,
  FETCHED_COLUMN_VALUES,
  FETCH_COLUMN_VALUES
} from "../types/ninjaDl";

const initialState = {
  allDls: [],
  managedDls: [],
  customDls: [],
  selectedDl: null,
  error: false,
  loading: false,
  fetched: false,
  savingCount: 0,
  uploadTaskId: null,
  selectedColumnValues: [],
  selectedColumnName: null
};

const mapDls = (dls, prevSelectedDl) => {
  if (!dls) return {};
  var customDls = [];
  var managedDls = [];
  var allDls = [];
  var foundSelected = false;
  var selectedDl = prevSelectedDl;
  for (var dl of dls) {
    if (dl) {
      if (selectedDl && selectedDl.id == dl.id) {
        selectedDl = dl;
        foundSelected = true;
      }
      allDls.push(dl);
      if (dl.type == 1) managedDls.push(dl);
      else if (dl.type == 0) customDls.push(dl);
    }
  }
  if (!foundSelected && selectedDl) selectedDl = null;
  if (selectedDl == null) {
    if (managedDls && managedDls.length > 0) selectedDl = managedDls[0];
    else if (customDls && customDls.length > 0) selectedDl = customDls[0];
  }
  return { customDls, managedDls, allDls, selectedDl };
};

const removeDl = (dl, state) => {
  if (!dl) return state.allDls;
  var newDls = [];
  for (var d of state.allDls) if (d && d.id != dl.id) newDls.push(d);
  return newDls;
};

const updateDl = (newDl, state) => {
  if (!newDl) return state.allDls;
  var newDls = [];
  var added = false;
  for (var dl of state.allDls) {
    if (dl.id == newDl.id) {
      newDls.push(newDl);
      added = true;
    } else newDls.push(dl);
  }
  if (!added) newDls.push(newDl);
  return newDls;
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_DLS_SUCCEEDED:
      var mapping = mapDls(action.dls, state.selectedDl);
      return {
        ...state,
        ...mapping,
        loading: false,
        fetched: true,
        error: false
      };
    case FETCH_DLS:
      return {
        ...state,
        loading: true
      };
    case FETCH_DLS_FAILED:
      return {
        ...state,
        loading: false,
        fetched: true,
        error: true
      };
    case SET_SELECTED_DL:
      return {
        ...state,
        selectedDl: action.dl
      };
    case UPDATE_DL:
      let newDl = updateDl(action.dl, state);
      mapping = mapDls(newDl, state.selectedDl);
      return {
        ...state,
        ...mapping
      };
    case REMOVE_DL:
      let newDls = removeDl(action.dl, state);
      mapping = mapDls(newDls, state.selectedDl);
      return {
        ...state,
        ...mapping
      };
    case SET_SAVING:
      if (action.value) return { ...state, savingCount: state.savingCount + 1 };
      else return { ...state, savingCount: state.savingCount - 1 };
    case SET_UPLOAD_TASKID:
      return { ...state, uploadTaskId: action.id };
    case FETCHED_COLUMN_VALUES:
      return {
        ...state,
        selectedColumnValues: action.values
      };
    case FETCH_COLUMN_VALUES:
      var newColumnValues = {};
      if (action.column != state.selectedColumnName)
        newColumnValues = { selectedColumnValues: [] };
      return {
        ...state,
        ...newColumnValues,
        selectedColumnName: action.column
      };
    default:
      return state;
  }
}
