import { FETCH_APPS_SUCCESS, FETCH_APPS_FAILED } from "../types/apps";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_APPS_SUCCESS:
      return {
        ...state,
        data: action.apps
      };
    case FETCH_APPS_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
