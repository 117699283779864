import {
  FETCH_DATA_SOURCE_CONDITIONS_SUCCESS,
  FETCH_DATA_SOURCE_CONDITIONS_FAILED
} from "../types/dataSourceCondition";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_DATA_SOURCE_CONDITIONS_SUCCESS:
      return {
        ...state,
        data: action.dataSourceConditions
      };
    case FETCH_DATA_SOURCE_CONDITIONS_FAILED:
      return {
        ...state
      };
    default:
      return state;
  }
}
