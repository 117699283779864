import {
  ADD_MANAGED_TABLE_USER_SEARCH_FILTER,
  ADD_MANAGED_USER_TABLE_FILTER_FAILED,
  ADD_MANAGED_USER_TABLE_FILTER_SUCCEEDED,
  CHANGE_SEARCH_COLUMN_FILTER,
  CLEAR_MANAGED_TABLE_USER_SEARCH_FILTER,
  CLEAR_SEARCH_COLUMN_FILTER,
  DELETE_MANAGED_USER_TABLE_FILTER_FAILED,
  DELETE_MANAGED_USER_TABLE_FILTER_SUCCEEDED,
  FETCH_MANAGED_USERS_TABLES,
  FETCH_MANAGED_USERS_TABLES_FAILED,
  FETCH_MANAGED_USERS_TABLES_FILTER,
  FETCH_MANAGED_USERS_TABLES_FILTER_FAILED,
  FETCH_MANAGED_USERS_TABLES_FILTER_SUCCEEDED,
  FETCH_MANAGED_USERS_TABLES_SUCCEEDED,
  FETCH_MANAGED_USER_TABLE_COLUMN_KEYS,
  FETCH_MANAGED_USER_TABLE_COLUMN_KEYS_FAILED,
  FETCH_MANAGED_USER_TABLE_COLUMN_KEYS_SUCCESS,
  GET_MANAGED_USER_TABLE_DATA,
  GET_MANAGED_USER_TABLE_DATA_FAILED,
  GET_MANAGED_USER_TABLE_DATA_SUCCEEDED,
  SET_COLUMN_FILTER_VALUE,
  SET_MANAGED_USER_TABLE,
  SET_SEARCH_VALUE,
  UPDATE_MANAGED_USER_TABLE_FILTER_FAILED,
  UPDATE_MANAGED_USER_TABLE_FILTER_SUCCEEDED
} from "../types/managedUser";
import { RESET_BUILDER } from "../types/reset";

const initialState = {
  isLoadingTables: false,
  isLoadingFilters: false,
  tables: [],
  filter: {},
  filtersList: [],
  managedUserTable: null,
  columns: {},
  data: [],
  isDataLoading: false,
  dataTotalCount: null,
  openedFilter: null,
  searchFilters: [],
  searchValues: [],
  disabled: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_MANAGED_USERS_TABLES:
      return {
        ...state,
        isLoadingTables: true
      };
    case FETCH_MANAGED_USERS_TABLES_SUCCEEDED:
      return {
        ...state,
        tables: action.tables,
        isLoadingTables: false
      };
    case FETCH_MANAGED_USERS_TABLES_FAILED:
      return {
        ...state,
        isLoadingTables: false
      };
    case FETCH_MANAGED_USERS_TABLES_FILTER:
      return {
        ...state,
        isLoadingFilters: true
      };
    case SET_MANAGED_USER_TABLE:
      return {
        ...state,
        managedUserTable: action.managedUserTable
      };
    case FETCH_MANAGED_USERS_TABLES_FILTER_SUCCEEDED:
      return {
        ...state,
        filtersList: action.filtersList,
        managedUserTable: action.managedUserTable,
        isLoadingFilters: false
      };
    case FETCH_MANAGED_USERS_TABLES_FILTER_FAILED:
      return {
        ...state,
        isLoadingFilters: false
      };
    case FETCH_MANAGED_USER_TABLE_COLUMN_KEYS:
      return {
        ...state,
        columns: {
          ...state.columns,
          [action.column]: {
            ...state.columns[action.column],
            isLoading: true
          }
        }
      };
    case FETCH_MANAGED_USER_TABLE_COLUMN_KEYS_SUCCESS:
      return {
        ...state,
        columns: {
          ...state.columns,
          [action.column]: {
            ...state.columns[action.column],
            keys: action.keys,
            totalCount: action.totalCount,
            isLoading: false
          }
        },
        disabled: { ...action.disabled }
      };
    case FETCH_MANAGED_USER_TABLE_COLUMN_KEYS_FAILED:
      return {
        ...state,
        columns: {
          ...state.columns,
          [action.column]: {
            ...state.columns[action.column],
            isLoading: false
          }
        }
      };
    case ADD_MANAGED_USER_TABLE_FILTER_SUCCEEDED:
      return {
        ...state,
        filtersList: action.filtersList
      };
    case ADD_MANAGED_USER_TABLE_FILTER_FAILED:
      return {
        ...state
      };
    case GET_MANAGED_USER_TABLE_DATA:
      return {
        ...state,
        isDataLoading: true
      };
    case GET_MANAGED_USER_TABLE_DATA_SUCCEEDED:
      return {
        ...state,
        isDataLoading: false,
        data: action.data,
        dataTotalCount: action.dataTotalCount
      };
    case GET_MANAGED_USER_TABLE_DATA_FAILED:
      return {
        ...state,
        isDataLoading: false
      };
    case SET_COLUMN_FILTER_VALUE:
      return {
        ...state,
        columns: {
          ...state.columns,
          [action.column]: {
            ...state.columns[action.column],
            filterValue: action.filterValue
          }
        }
      };
    case ADD_MANAGED_TABLE_USER_SEARCH_FILTER:
      return {
        ...state,
        searchFilters: [...state.searchFilters, action.searchFilter],
        searchValues: [...state.searchValues, ""]
      };
    case CLEAR_MANAGED_TABLE_USER_SEARCH_FILTER:
      return {
        ...state,
        searchFilters: action.searchFilters,
        searchValues: action.searchValues
      };
    case CHANGE_SEARCH_COLUMN_FILTER:
      return {
        ...state,
        searchFilters: [...action.searchFilters]
      };
    case CLEAR_SEARCH_COLUMN_FILTER:
      return {
        ...state,
        searchFilters: [...[]],
        searchValues: [...[]]
      };
    case UPDATE_MANAGED_USER_TABLE_FILTER_SUCCEEDED:
      return {
        ...state,
        filtersList: action.filtersList
      };
    case UPDATE_MANAGED_USER_TABLE_FILTER_FAILED:
      return {
        ...state
      };
    case SET_SEARCH_VALUE:
      return {
        ...state,
        searchValues: [...action.searchValues]
      };
    case DELETE_MANAGED_USER_TABLE_FILTER_SUCCEEDED:
      return {
        ...state,
        filtersList: action.filtersList
      };
    case DELETE_MANAGED_USER_TABLE_FILTER_FAILED:
      return {
        ...state
      };
    case RESET_BUILDER:
      return {
        ...initialState
      };
    default:
      return state;
  }
}
