import { OPEN_SNACKBAR, CLOSE_SNACKBAR } from "../types/snackbar";

const initialState = {
  open: false,
  snackbarType: "",
  message: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {
        ...state,
        open: true,
        snackbarType: action.snackbarType,
        message: action.message
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        open: false
      };
    default:
      return state;
  }
}
