import { SET_TENANT } from "../types/tenant";

export const setTenant = tenant => {
  return dispatch => {
    dispatch({
      type: SET_TENANT,
      tenant
    });
  };
};
